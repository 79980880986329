import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
// Components
import UpiCollectionSuccessDialog from "../../../UpiCollectionSuccessDialog/UpiCollectionSuccessDialog";
import { Loader } from "rsuite";
import FailureForCTA from "../../../Shared/FailureForCTA/FailureForCTA";
import unMountPreviousComponent from "../../../../utilities/unMountPreviousComponent";
import TextFieldInput from "../../../UI/TextField/TextFieldInput";
// Formik Imoports
import { useFormik } from "formik";
import * as Yup from "yup";
// Axios Imports
import Axios from "axios";
// Regex Imports
import { REGEXP } from "../../../utilities/validators/inputValidators";
// Utils
import apiEndpointList from "../../../../config/core_banking/endpoint";
import { APIConfig } from "../../../../services/apiConfiguration";
import apiEndpointListPayments from "../../../../config/payments/endpoint";
import { capturePosthogEventCustom } from "../../../../utilities/postHogCustomUtils";
// Styles
import "./UpiCollectionForm.scss";

function UpiCollectionForm() {
  const CancelToken = Axios.CancelToken;
  const source = CancelToken.source();

  // for holding Provider Bank
  const [providerApiData, setProviderApiData] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Calling provider options API
  const cvaProvider = () => {
    setIsLoading(true);
    APIConfig.API_Client.post(
      apiEndpointList.PROVIDER_COLLECTION.baseUrl +
        apiEndpointList.PROVIDER_COLLECTION.endpoint,
      {},
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        setIsLoading(false);
        const data = response.data.map(({ bank_code, bank_name }) => ({
          value: bank_code,
          label: bank_name,
        }));
        setProviderApiData(data[0]);
      })
      .catch((error) => {
        console.error("error from UPIPaymentLinkForm", error);
        setIsLoading(false);
      });
  };

  // Fetching CVA Providers
  useEffect(() => {
    cvaProvider();
  }, []);

  const initialValues = {
    payee_account: "",
    amount: "",
    purpose_message: "",
    reference_id: "",
    bank_provider: "",
    payer_upi: "",
  };

  const {
    payerAccountRegex,
    amountRegex,
    messageRegex,
    UPIIDRegex,
    referenceIdRegex,
  } = REGEXP;

  // Schema for validating form fields corresponding to regex imported.
  const validationSchema = Yup.object({
    payee_account: Yup.string()
      .matches(payerAccountRegex)
      .required("Payee Account is required"),
    amount: Yup.number()
      .required()
      .positive()
      .test(
        "is-amount",
        "Must be a valid number over or equal to 1",
        (number) => amountRegex.test(number)
      ),
    purpose_message: Yup.string()
      .matches(messageRegex)
      .required("It's a required field"),
    payer_upi: Yup.string().matches(UPIIDRegex),
    reference_id: Yup.string().required().matches(referenceIdRegex),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,

    // This function will run when user will submit the form after it is validated.
    onSubmit: (values, action) => {
      const payloadData = {
        reference_id: values.reference_id,
        payee_account: values.payee_account,
        amount: +values.amount,
        purpose_message: values.purpose_message,
        payer_upi: values.payer_upi,
      };
      if (
        process.env.REACT_APP_ENV_NAME === "staging" ||
        process.env.REACT_APP_ENV_NAME === "production"
      ) {
        if (window?.location?.origin && window?.location?.pathname) {
          try {
            capturePosthogEventCustom(
              window.location.origin,
              window.location.pathname,
              "Send Collection Request CTA Submit",
              {
                additionalInfo: "User submitted Collection Request details",
              }
            );
          } catch (error) {
            console.error("Error capturing Posthog event:", error);
          }
        } else {
          console.warn(
            "Unable to capture event: window.location properties are not fully defined"
          );
        }
      }
      upiPaymentLinkData(payloadData, providerApiData.value, action);
    },
  });

  // For storing the payload input data by user.
  let upiPaymentInputData;
  // For storing the bank provider name selected by user.
  let bankProvider;

  const upiPaymentLinkData = (inputData, provider, action) => {
    upiPaymentInputData = inputData;
    bankProvider = provider;
    // Setting the submission of form true using formik inbuilt action.
    action.setSubmitting(true);
    // Submission of form
    upiCollectionRequest(action);
  };

  // UPI collection API call
  const upiCollectionRequest = (action) => {
    ReactDOM.render(<Loader />, document.getElementById("user-config-loader"));
    let payloadData = upiPaymentInputData;

    APIConfig.API_Client.defaults.headers.post["provider_code"] = bankProvider;

    APIConfig.API_Client.post(
      apiEndpointListPayments.UPI_COLLECTION_REQUEST.baseUrl +
        apiEndpointListPayments.UPI_COLLECTION_REQUEST.endpoint,
      payloadData,
      { cancelToken: source.token }
    )
      .then((response) => {
        action.resetForm();
        ReactDOM.unmountComponentAtNode(
          document.getElementById("user-config-loader")
        );

        ReactDOM.render(
          <UpiCollectionSuccessDialog successResponse={response.data} />,
          document.getElementById("payments-components")
        );
      })
      .catch((error) => {
        ReactDOM.unmountComponentAtNode(
          document.getElementById("user-config-loader")
        );
        unMountPreviousComponent("payments-components");
        ReactDOM.render(
          <FailureForCTA
            header={"UPI Collection Request"}
            msg={error.response.data}
          />,
          document.getElementById("failure-popup")
        );
      })
      .finally(() => {
        // finally setting form submission false.
        action.setSubmitting(false);
      });
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="ui-form-details">
          <div className="ui-form-content">
            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="bank_provider"
                name="bank_provider"
                value={isLoading ? "LOADING" : providerApiData.label}
                placeholder="Enter Bank Provider"
                label="Bank Provider"
                required={true}
                disabled={true}
              />
              <TextFieldInput
                id="reference_id"
                name="reference_id"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.reference_id}
                touched={formik.touched.reference_id}
                error={formik.errors.reference_id}
                placeholder="Enter Reference ID"
                label="Reference ID"
                required={true}
                disabled={false}
              />
            </div>

            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="payee_account"
                name="payee_account"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.payee_account}
                touched={formik.touched.payee_account}
                error={formik.errors.payee_account}
                placeholder="Enter Account Number"
                label="Account"
                required={true}
                disabled={false}
                isToolTip={
                  true
                    ? "Virtual Account Number in which the amount will be received"
                    : null
                }
              />
              <TextFieldInput
                id="amount"
                name="amount"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.amount}
                touched={formik.touched.amount}
                error={formik.errors.amount}
                placeholder="Enter Amount"
                label="Amount"
                required={true}
                disabled={false}
                isToolTip={true ? "A minimum of INR 1 is accepted" : null}
              />
            </div>

            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="purpose_message"
                name="purpose_message"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.purpose_message}
                touched={formik.touched.purpose_message}
                error={formik.errors.purpose_message}
                placeholder="Enter Purpose Message"
                label="Message"
                required={true}
                disabled={false}
                isToolTip={
                  true ? "A purpose message for this transaction" : null
                }
              />
              <TextFieldInput
                id="payer_upi"
                name="payer_upi"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.payer_upi}
                touched={formik.touched.payer_upi}
                error={formik.errors.payer_upi}
                placeholder="Enter your UPI ID"
                label="UPI"
                required={true}
                disabled={false}
              />
            </div>

            <div className="ui-button-container">
              <button
                type="submit"
                className={`submit-btn ${
                  formik.isValid && formik.dirty && !formik.isSubmitting
                    ? "active"
                    : ""
                }`}
                disabled={!formik.isValid || formik.isSubmitting}
              >
                {formik.isSubmitting ? "Loading..." : "Submit"}
                <span
                  id="user-config-loader"
                  style={{ display: "flex" }}
                ></span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default UpiCollectionForm;
