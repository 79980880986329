// React and related imports
import React, { useContext, useEffect } from "react";
// Context provider
import { ManageBeneficairyActionProvider } from "../../contexts/ManageBeneficiaryContext";
import { BeneficiaryContextProvider } from "./ManageBeneficiaryContext";
// Custom components
import ReactDataTable from "../Common/ReactDataTable";
import ManageBeneficiaryModal from "../ManageBeneficiaryModal/ManageBeneficiary/ManageBeneficiary";
import EditCell from "./EditCell";
import SkeletonEmptyDialogue from "../SkeletonComponents/SkeletonEmptyDialogue";
// Utility functions
import TimeCell from "../../utilities/TimeCell";
import StatusCell from "../../utilities/StatusCell/StatusCell";
import posthog from "posthog-js";
import { capturePosthogEvent } from "../../utilities/posthogUtils";
import { capturePosthogEventCustom } from "../../utilities/postHogCustomUtils";

const transactionColumnList = [
  {
    accessor: "beneficiary_name",
    name: "Beneficiary Name",
    showInitial: true,
  },
  {
    accessor: "beneficiary_account_number",
    name: "Beneficiary Account",
    showInitial: true,
  },
  {
    accessor: "beneficiary_code",
    name: "Beneficiary Code",
    showInitial: true,
  },
  {
    accessor: "beneficiary_upi_id",
    name: "Beneficiary UPI",
    showInitial: true,
  },
  {
    accessor: "created_on",
    name: "Date-Time",
    cell: TimeCell,
    showInitial: true,
  },
  {
    accessor: "status",
    title: "status",
    name: "status",
    showInitial: true,
    hasPopup: true,
    cell: StatusCell,
  },
  {
    accessor: "transfer_type",
    name: "Transfer Type",
    showInitial: true,
    hasPopup: true,
  },

  { accessor: "ifsc_code", name: "IFSC Code" },
  {
    accessor: "beneficiary_email",
    name: "Beneficiary Email",
  },
  {
    accessor: "beneficiary_mobile",
    name: "Beneficiary Mobile",
  },
  {
    accessor: "sender_bank_account",
    name: "Remitter Account",
  },
  {
    accessor: "",
    cell: EditCell,
    name: "Manage",
    showInitial: true,
    // clickFn: viewTransactions,
  },
];
const Beneficiary = ({ openUpiLink }) => {
  const { isLoading } = useContext(BeneficiaryContextProvider);
  return (
    <>
      <button
        className={isLoading ? "btn-loading" : "virtual-accounts-btn"}
        style={{ backgroundColor: "#0092ff" }}
        onClick={() => {
          if (!isLoading) {
            if (
              process.env.REACT_APP_ENV_NAME === "staging" ||
              process.env.REACT_APP_ENV_NAME === "production"
            ) {
              if (window?.location?.origin && window?.location?.pathname) {
                try {
                  capturePosthogEventCustom(
                    window.location.origin,
                    window.location.pathname,
                    "Add Beneficiary CTA Click",
                    {
                      additionalInfo: "User clicked Add Beneficiary button",
                    }
                  );
                } catch (error) {
                  console.error("Error capturing Posthog event:", error);
                }
              } else {
                console.warn(
                  "Unable to capture event: window.location properties are not fully defined"
                );
              }
            }

            openUpiLink();
          }
        }}
      >
        Add Beneficiary
      </button>
    </>
  );
};

const ManageBeneficiaryReact = () => {
  // Posthog event capture on page visit
  useEffect(() => {
    if (
      process.env.REACT_APP_ENV_NAME === "staging" ||
      process.env.REACT_APP_ENV_NAME === "production"
    ) {
      if (window?.location?.origin && window?.location?.pathname) {
        try {
          capturePosthogEventCustom(
            window.location.origin,
            window.location.pathname,
            "Manage Beneficiaries View",
            {
              additionalInfo: "User viewed Manage Beneficiaries page",
            }
          );
        } catch (error) {
          console.error("Error capturing Posthog event:", error);
        }
      } else {
        console.warn(
          "Unable to capture event: window.location properties are not fully defined"
        );
      }
    }
  }, []);
  const {
    initialLoading,
    isError,
    errorResponse,
    refreshTable,
    setShowModalDialog,
    showModify,
    setShowModify,
    selectedAccount,
    showModalDialog,
    tableData,
    totalCount,
    fetchData,
    filterList,
    firstApiCall,
  } = useContext(BeneficiaryContextProvider);

  useEffect(() => {
    if (tableData.length >= totalCount && tableData.length !== 0) return;
    const prefetchLimit = 200;
    const endIndex = filterList.currentPage * filterList.resultsPerPage;
    const limitConstant =
      Math.ceil((endIndex + 1) / prefetchLimit) * prefetchLimit +
      2 * filterList?.resultsPerPage;
    if (totalCount > 0) {
      if (tableData.length < limitConstant && firstApiCall === false) {
        fetchData(tableData.length + 1, undefined, undefined, undefined, false);
      }
    } else if (
      totalCount === 0 &&
      tableData.length === 0 &&
      firstApiCall === true
    ) {
      fetchData(tableData.length + 1, undefined, undefined, undefined, false);
    }
  }, [filterList.currentPage]);

  function openUpiLink() {
    setShowModalDialog(true);
  }
  return (
    <>
      {!isError ? (
        <ReactDataTable
          tableTitle={"Manage Beneficiaries"}
          isModalPresent={true}
          showDownloadBtn={false}
          modalComponent={<Beneficiary openUpiLink={openUpiLink} />}
          columnList={transactionColumnList}
          provider={BeneficiaryContextProvider}
          ModalDialog={
            <ManageBeneficairyActionProvider>
              {showModify ? (
                <ManageBeneficiaryModal
                  tableEditRow={true}
                  data={selectedAccount}
                  goBackToTable={refreshTable}
                />
              ) : (
                <ManageBeneficiaryModal
                  tableEditRow={false}
                  goBackToTable={refreshTable}
                />
              )}
            </ManageBeneficairyActionProvider>
          }
        />
      ) : (
        <SkeletonEmptyDialogue type={errorResponse} />
      )}
    </>
  );
};

export default ManageBeneficiaryReact;
