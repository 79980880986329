import React, { useState } from "react";
import ReactDOM, { unmountComponentAtNode } from "react-dom";
import { useHistory } from "react-router-dom";
// Third Party Libraries
import KeyboardArrowDownSharp from "@material-ui/icons/KeyboardArrowDownSharp.js";
import axios from "axios";
import { clearAuthTokens } from "axios-jwt";
// Utils import
import { APIConfig } from "../../services/apiConfiguration.js";
import SendCredSuccessDialog from "../SendCredSuccessDialog/SendCredSuccessDialog";
import apiEndpointList from "../../config/core_banking/endpoint.js";
import { capturePosthogEvent } from "../../utilities/posthogUtils.js";
import posthog from "posthog-js";
import { capturePosthogEventCustom } from "../../utilities/postHogCustomUtils.js";

// styles import
import "./Header.scss";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const Header = () => {
  let history = useHistory();
  let user = JSON.parse(localStorage.getItem("user"));
  let loggedInFlag = JSON.parse(localStorage.getItem("loggedInFlag"));
  let [disable, setDisable] = useState(false);

  const Logout = () => {
    // Unmount any component at the "failure-popup" div
    const failurePopupDiv = document.getElementById("failure-popup");
    ReactDOM.unmountComponentAtNode(failurePopupDiv);
    APIConfig.API_Client.post(
      // APIConfig.BASE_URL + '/user/logout'
      apiEndpointList.USER_LOGOUT.baseUrl +
        apiEndpointList.USER_LOGOUT.endpoint,
      {},
      { cancelToken: source.token }
    )
      .then((response) => {
        if (
          process.env.REACT_APP_ENV_NAME === "staging" ||
          process.env.REACT_APP_ENV_NAME === "production"
        ) {
          if (window?.location?.origin && window?.location?.pathname) {
            try {
              capturePosthogEventCustom(
                window.location.origin,
                window.location.pathname,
                "Logout Successful",
                {
                  additionalInfo:
                    "User Logged out successfully using logout CTA",
                }
              );
            } catch (error) {
              console.error("Error capturing Posthog event:", error);
            }
          } else {
            console.warn(
              "Unable to capture event: window.location properties are not fully defined"
            );
          }
        }
        clearAuthTokens();
        history.push("/login");
      })
      .catch((err) => {
        clearAuthTokens();
        history.push("/login");
      });
  };

  const toggleMenu = () => {
    document.querySelector(".dropdown-content").classList.toggle("active");
  };

  const closeDropDown = () => {
    document.querySelector(".dropdown-content").classList.remove("active");
  };

  const closeDialog = () => {
    ReactDOM.unmountComponentAtNode(
      document.getElementById("reports-component")
    );
  };

  const handleMail = () => {
    setDisable(true);
    APIConfig.API_Client.post(
      apiEndpointList.EMAIL_COMPANY_CREDENTIALS.baseUrl +
        apiEndpointList.EMAIL_COMPANY_CREDENTIALS.endpoint
      // APIConfig.BASE_URL + '/v2/internal/email_company_credentials'
    ).then((response) => {
      ReactDOM.render(
        <SendCredSuccessDialog closeDialog={closeDialog} />,
        document.getElementById("reports-component")
      );
      setTimeout(() => {
        setDisable(false);
      }, 10000);
    });
  };

  if (!loggedInFlag) {
    Logout();
  }

  //constant variable for changelog link
  let changelogLink = `${process.env.REACT_APP_CHANGE_LOG}/changelog`;
  // let envConstant = process.env.REACT_APP_ENV_NAME !== "production";

  return (
    <div className="header">
      <div className="left-side">
        <img src="/images/decentro-logo.svg" alt="logo" />
      </div>
      <div className="right-side">
        <div className="user">
          {/* {envConstant && (
            <button
              className="btn_mail"
              disabled={disable}
              onClick={handleMail}
            >
              Send Credentials On Email
            </button>
          )} */}
          <a
            className="header-btn product-status-btn"
            href="https://status.decentro.tech"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              if (
                process.env.REACT_APP_ENV_NAME === "staging" ||
                process.env.REACT_APP_ENV_NAME === "production"
              ) {
                if (window?.location?.origin && window?.location?.pathname) {
                  try {
                    capturePosthogEventCustom(
                      window.location.origin,
                      window.location.pathname,
                      "Check API Status CTA Click",
                      {
                        additionalInfo: "User clicked Check API Status button",
                      }
                    );
                  } catch (error) {
                    console.error("Error capturing Posthog event:", error);
                  }
                } else {
                  console.warn(
                    "Unable to capture event: window.location properties are not fully defined"
                  );
                }
              }
            }}
          >
            Check API Status.
          </a>
          <a
            className="header-btn change-log-btn"
            href={changelogLink}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              if (
                process.env.REACT_APP_ENV_NAME === "staging" ||
                process.env.REACT_APP_ENV_NAME === "production"
              ) {
                if (window?.location?.origin && window?.location?.pathname) {
                  try {
                    capturePosthogEventCustom(
                      window.location.origin,
                      window.location.pathname,
                      "ChangeLog CTA Click",
                      {
                        additionalInfo: "User clicked ChangeLog button",
                      }
                    );
                  } catch (error) {
                    console.error("Error capturing Posthog event:", error);
                  }
                } else {
                  console.warn(
                    "Unable to capture event: window.location properties are not fully defined"
                  );
                }
              }
            }}
          >
            Change Log
          </a>
          <div className="dropdown" tabIndex="0" onBlur={closeDropDown}>
            <span onClick={toggleMenu}>
              {user.name ? user.name : "User"} <KeyboardArrowDownSharp />
            </span>
            <div className="dropdown-content">
              <ul>
                <li>Logged in with {user.email ? user.email : "your email"}</li>
                <li
                  className="logout"
                  onClick={() => {
                    // if (
                    //   process.env.REACT_APP_ENV_NAME === "staging" ||
                    //   process.env.REACT_APP_ENV_NAME === "production"
                    // ) {
                    //   capturePosthogEvent(
                    //     window.location.origin,
                    //     window.location.pathname,
                    //     "logout"
                    //   );
                    // }

                    Logout();
                  }}
                >
                  Logout
                </li>
              </ul>
            </div>
          </div>
          <div className="avatar">{user.name ? user.name[0] : "User"}</div>
        </div>
      </div>
    </div>
  );
};

export default Header;
