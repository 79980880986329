import ReactDOM from "react-dom";
import { useEffect, useState } from "react";
// Formik Imports
import { useFormik } from "formik";
import * as Yup from "yup";
// Components
import TextFieldSelect from "../../UI/TextField/TextFieldSelect";
import TextFieldInput from "../../UI/TextField/TextFieldInput";
import FailureForCTA from "../../Shared/FailureForCTA/FailureForCTA";
import unMountPreviousComponent from "../../../utilities/unMountPreviousComponent";
import BulkCapabilitiesSuccessDialog from "./BulkCapabilitiesSuccessDialog/BulkCapabilitiesSuccessDialog";
import DownloadButton from "../../UI/DownloadButton/DownloadButton";
import { Loader } from "rsuite";
// Utils
import { REGEXP } from "../../utilities/validators/inputValidators";
import {
  APIConfig,
  APIConfigFormData,
} from "../../../services/apiConfigurationClientID";
import apiEndpointList from "../../../config/core_banking/endpoint";
import { capturePosthogEventCustom } from "../../../utilities/postHogCustomUtils";
// Axios
import Axios from "axios";
// Styles
import "../BulkCapabilities.scss";

const CancelToken = Axios.CancelToken;
const source = CancelToken.source();

const BulkForm = () => {
  const InputType = "CSV";
  const bulkUploadTypePayout = {
    code: "bulk_payout",
    name: "Bulk Payout",
  };

  // for holding Provider Bank
  const [bulkUploadTypes, setBulkUploadTypes] = useState("");

  // For holding
  const [providerApiData, setProviderApiData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    bulk_upload_type: bulkUploadTypePayout.name,
    bank_provider: "",
    reference_id: "",
    input_type: InputType,
    bulkPayoutFile: null,
  };

  const { referenceIdRegex } = REGEXP;

  // Schema for validating form fields corresponding to regex imported.
  const validationSchema = Yup.object({
    bulk_upload_type: Yup.mixed().required("Bulk Upload Type is required"),
    bank_provider: Yup.mixed().required("Bank Provider is required"),
    reference_id: Yup.string().required().matches(referenceIdRegex),
    input_type: Yup.string(),
    bulkPayoutFile: Yup.mixed()
      .required("File is required")
      .test(
        "fileType",
        "Only CSV files are allowed",
        (value) => value && value.type === "text/csv"
      ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,

    // This function will run when user will submit the form after it is validated.
    onSubmit: (values, action) => {
      const bulkCsvfile = document.getElementById("bulkPayoutFile").files[0];
      const formDataBulk = new FormData();
      formDataBulk.set("input", bulkCsvfile);
      formDataBulk.append("reference_id", values.reference_id);
      formDataBulk.append("input_type", values.input_type);
      if (
        process.env.REACT_APP_ENV_NAME === "staging" ||
        process.env.REACT_APP_ENV_NAME === "production"
      ) {
        if (window?.location?.origin && window?.location?.pathname) {
          try {
            capturePosthogEventCustom(
              window.location.origin,
              window.location.pathname,
              "Bulk Upload CTA Submit",
              {
                additionalInfo: "User submitted Bulk Upload details",
              }
            );
          } catch (error) {
            console.error("Error capturing Posthog event:", error);
          }
        } else {
          console.warn(
            "Unable to capture event: window.location properties are not fully defined"
          );
        }
      }
      bulkFormSubmission(formDataBulk, action);
    },
  });

  //&F bulkFormSubmission API call
  const bulkFormSubmission = (payloadData, action) => {
    action.setSubmitting(true);
    ReactDOM.render(<Loader />, document.getElementById("user-config-loader"));

    APIConfigFormData.API_Client.defaults.headers.post["provider_code"] =
      formik?.values?.bank_provider?.value;
    APIConfigFormData.API_Client.defaults.headers.post["client_id"] =
      JSON.parse(localStorage.getItem("user")).client_id;

    APIConfigFormData.API_Client.post(
      apiEndpointList.BULK_FORM_SUBMISSION.baseUrl +
        apiEndpointList.BULK_FORM_SUBMISSION.endpoint +
        `/${bulkUploadTypePayout?.code}`,
      payloadData,
      { cancelToken: source.token }
    )
      .then((response) => {
        action.resetForm();
        ReactDOM.unmountComponentAtNode(
          document.getElementById("user-config-loader")
        );

        ReactDOM.render(
          <BulkCapabilitiesSuccessDialog successResponse={response.data} />,
          document.getElementById("payments-components")
        );

        // Setting to empty string
        const bulkCsvPayoutFile = document.getElementById("bulkPayoutFile");
        if (bulkCsvPayoutFile) {
          bulkCsvPayoutFile.value = "";
        }
      })
      .catch((error) => {
        ReactDOM.unmountComponentAtNode(
          document.getElementById("user-config-loader")
        );
        unMountPreviousComponent("payments-components");
        ReactDOM.render(
          <FailureForCTA
            header={"Bulk Upload"}
            msg={error?.response?.data?.message}
          />,
          document.getElementById("failure-popup")
        );
      })
      .finally(() => {
        // finally setting form submission false.
        action.setSubmitting(false);
      });
  };

  //TODO: & Fetching the Bulk Upload Type to show in dropdown.
  //   const bulkUploadType = () => {
  //     setIsLoading(true);
  //     APIConfig.API_Client.post(
  //       apiEndpointList.FETCH_BULK_UPLOAD_TYPES.baseUrl +
  //         apiEndpointList.FETCH_BULK_UPLOAD_TYPES.endpoint,
  //       { module_name: "payments" },
  //       {
  //         cancelToken: source.token,
  //       }
  //     )
  //       .then((response) => {
  //         const options = response.data.map((item) => ({
  //           value: item.code.toLowerCase(),
  //           label: item.name,
  //         }));
  //         setIsLoading(false);
  //         setBulkUploadTypes(options);
  //       })
  //       .catch((error) => {
  //         console.error("error from Bulk Form", error);
  //       });
  //   };

  //   useEffect(() => {
  // bulkUploadType()
  //   }, []);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    formik.setFieldValue("bulkPayoutFile", file);
  };

  // Fetching the Providers to show in dropdown.
  const cvaProvider = () => {
    setIsLoading(true);
    APIConfig.API_Client.post(
      apiEndpointList.PROVIDER_ACCOUNTS.baseUrl +
        apiEndpointList.PROVIDER_ACCOUNTS.endpoint,
      {},
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        const options = response.data.map((item) => ({
          value: item.bank_code,
          label: item.bank_name,
          bank_id: item.bank_id,
        }));
        setIsLoading(false);
        setProviderApiData(options);
      })
      .catch((error) => {
        console.error("error from Payout Form", error);
      });
  };

  useEffect(() => cvaProvider(), []);

  const handleDownload = () => {
    const fileUrl = "/files/bulk-payout-sample.csv";
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = "Bulk Payout";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="ui-form-details">
          <div className="ui-form-content">
            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="bulk_upload_type"
                name="bulk_upload_type"
                value={formik.values.bulk_upload_type}
                label="Bulk Upload Type"
                required={true}
                disabled={true}
              />
              {/* * Uncomment if multiple options and fetching from API */}
              {/* <TextFieldSelect
                                id="bulk_upload_type"
                                name="bulk_upload_type"
                                onChange={(selectedOption) => {
                                    formik.setFieldValue("bulk_upload_type", selectedOption)
                                }
                                }
                                onBlur={() =>
                                    formik.setFieldTouched("bulk_upload_type", true)
                                }
                                value={formik.values.bulk_upload_type}
                                options={bulkUploadTypes}
                                noOptionsMessage={() => "No Bulk Upload Type Exists"}
                                label="Bulk Upload Type"
                                required={true}
                                isLoading={isLoading}
                                placeholder="Select the Bulk Upload Type"
                                isformatOptionLabel={true}
                            /> */}
              <TextFieldSelect
                id="bank_provider"
                name="bank_provider"
                onChange={(selectedOption) => {
                  formik.setFieldValue("bank_provider", selectedOption);
                }}
                onBlur={() => formik.setFieldTouched("bank_provider", true)}
                value={formik.values.bank_provider}
                options={providerApiData}
                noOptionsMessage={() => "No Provider Exists"}
                label="Select Provider"
                required={true}
                isLoading={isLoading}
                placeholder="Bank Provider"
                isformatOptionLabel={true}
              />
            </div>

            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="input_type"
                name="input_type"
                value={formik.values.input_type}
                label="Input Type"
                required={true}
                disabled={true}
              />

              <TextFieldInput
                id="reference_id"
                name="reference_id"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.reference_id}
                touched={formik.touched.reference_id}
                error={formik.errors.reference_id}
                placeholder="Enter Reference ID"
                label="Reference ID"
                required={true}
                disabled={false}
              />
            </div>

            <div className="ui-form-inputs-section">
              <div className={`ui-form-content-input`}>
                <div className="ui-file-input-container">
                  <div className="ui-input-file">
                    <input
                      type="file"
                      id="bulkPayoutFile"
                      name="bulkPayoutFile"
                      accept=".csv"
                      onChange={handleFileUpload}
                      required={true}
                    />
                    <div
                      className="ui-download-sample-file"
                      onClick={handleDownload}
                    >
                      <img src="/images/download_sample_file.svg" />
                      <div>Download Sample File</div>
                    </div>
                  </div>
                </div>

                <label htmlFor="bulkPayoutFile" className="ui-file-label">
                  {"Upload File"} <span className="required-field">*</span>
                </label>
                {formik.values.bulkPayoutFile &&
                formik.values.bulkPayoutFile.type !== "text/csv" ? (
                  <img
                    src="/images/invalid-input.svg"
                    className="ui-invalid-file-type"
                    alt="invalid-input"
                  />
                ) : null}
              </div>
            </div>

            <div className="ui-button-container">
              <button
                type="submit"
                className={`submit-btn ${
                  formik.isValid && formik.dirty && !formik.isSubmitting
                    ? "active"
                    : ""
                }`}
                disabled={!formik.isValid || formik.isSubmitting}
              >
                {formik.isSubmitting ? "Loading..." : "Submit"}
                <span
                  id="user-config-loader"
                  style={{ display: "flex" }}
                ></span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default BulkForm;
