import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
// Components
import UpiPaymentSuccessDialog from "../../../UpiPaymentSuccessDialog/UpiPaymentSuccessDialog";
import unMountPreviousComponent from "../../../../utilities/unMountPreviousComponent";
import FailureForCTA from "../../../Shared/FailureForCTA/FailureForCTA";
import { Loader } from "rsuite";
import TextFieldInput from "../../../../components/UI/TextField/TextFieldInput";
import Tooltip from "../../../Shared/Tooltip/Tooltip";
import TextFieldSelect from "../../../../components/UI/TextField/TextFieldSelect";
// Axios Imports
import Axios from "axios";
//REGEX-import
import { REGEXP } from "../../../../components/utilities/validators/inputValidators";
//formik-import
import { useFormik } from "formik";
import * as Yup from "yup";
// Utils
import apiEndpointList from "../../../../config/core_banking/endpoint";
import apiEndpointListpayments from "../../../../config/payments/endpoint";
import { APIConfig } from "../../../../services/apiConfiguration";
import { capturePosthogEventCustom } from "../../../../utilities/postHogCustomUtils";
// Styles
import "./UpiPaymentLinkForm.scss";

function UpiPaymentLinkForm() {
  const CancelToken = Axios.CancelToken;
  const source = CancelToken.source();

  // QR generation as selected by user.
  const [generateQR, setGenerateQR] = useState(true);

  // for holding Provider Bank
  const [providerApiData, setProviderApiData] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  //  ! If there are more than one provider, make changes to code with "**provider"

  // Calling provider options API
  const cvaProvider = () => {
    setIsLoading(true);
    APIConfig.API_Client.post(
      apiEndpointList.PROVIDER_PAYMENTS.baseUrl +
        apiEndpointList.PROVIDER_PAYMENTS.endpoint,
      {},
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        setIsLoading(false);
        const data = response.data.map(({ bank_code, bank_name }) => ({
          value: bank_code,
          label: bank_name,
        }));
        // ! **provider
        setProviderApiData(data[0]);
      })
      .catch((error) => {
        console.error("error from UPIPaymentLinkForm", error);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    cvaProvider();
  }, []);

  const initialValues = {
    payee_account: "",
    amount: "",
    purpose_message: "",
    reference_id: "",
    bank_provider: "",
    expiry_time: "",
  };

  const {
    payerAccountRegex,
    amountRegex,
    messageRegex,
    expiryRegex,
    referenceIdRegex,
  } = REGEXP;

  // Schema for validating form fields corresponding to regex imported.
  const validationSchema = Yup.object({
    payee_account: Yup.string()
      .matches(payerAccountRegex)
      .required("Payee Account is required"),
    amount: Yup.number()
      .required()
      .positive()
      .test(
        "is-amount",
        "Must be a valid number over or equal to 5",
        (number) => amountRegex.test(number)
      ),
    purpose_message: Yup.string()
      .matches(messageRegex)
      .required("It's a required field"),
    expiry_time: Yup.string().matches(expiryRegex),
    reference_id: Yup.string().required().matches(referenceIdRegex),
    // ! **provider: Uncomment below for provider validation.
    // bank_provider: Yup.object().shape({
    //   value: Yup.string().required("Please select an option"),
    //   label: Yup.string().required("Please select an option"),
    // }),
  });

  // Using Inbuilt Formik Methods for different actions including validation,blur etc.
  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,

    // This function will run when user will submit the form after it is validated.
    onSubmit: (values, action) => {
      const payloadData = {
        reference_id: values.reference_id,
        payee_account: values.payee_account,
        amount: +values.amount,
        purpose_message: values.purpose_message,
        generate_qr: generateQR ? 1 : 0,
        customized_qr_with_logo: 0,
        expiry_time: +values.expiry_time > 0 ? +values.expiry_time : "",
      };
      if (
        process.env.REACT_APP_ENV_NAME === "staging" ||
        process.env.REACT_APP_ENV_NAME === "production"
      ) {
        if (window?.location?.origin && window?.location?.pathname) {
          try {
            capturePosthogEventCustom(
              window.location.origin,
              window.location.pathname,
              "Generate Payment Link CTA Submit",
              {
                additionalInfo: "User submitted a payment link",
              }
            );
          } catch (error) {
            console.error("Error capturing Posthog event:", error);
          }
        } else {
          console.warn(
            "Unable to capture event: window.location properties are not fully defined"
          );
        }
      }
      // ! **provider
      upiPaymentLinkData(payloadData, providerApiData.value, action);
    },
  });

  // For storing the payload input data by user.
  let upiPaymentInputData;
  // For storing the bank provider name selected by user.
  let bankProvider;

  const upiPaymentLinkData = (inputData, provider, action) => {
    upiPaymentInputData = inputData;
    bankProvider = provider;
    // Setting the submission of form true using formik inbuilt action.
    action.setSubmitting(true);
    // Submission of form
    upiCollectionRequest(action);
  };

  // Payment Link Generation Function
  const upiCollectionRequest = (action) => {
    ReactDOM.render(<Loader />, document.getElementById("user-config-loader"));
    let payloadData = upiPaymentInputData;

    APIConfig.API_Client.defaults.headers.post["provider_code"] = bankProvider;

    APIConfig.API_Client.post(
      apiEndpointListpayments.UPI_PAYMENT_LINK.baseUrl +
        apiEndpointListpayments.UPI_PAYMENT_LINK.endpoint,
      payloadData,
      { cancelToken: source.token }
    )
      .then((response) => {
        action.resetForm();
        ReactDOM.unmountComponentAtNode(
          document.getElementById("user-config-loader")
        );

        ReactDOM.render(
          <UpiPaymentSuccessDialog successResponse={response.data} />,
          document.getElementById("payments-components")
        );
      })
      .catch((error) => {
        ReactDOM.unmountComponentAtNode(
          document.getElementById("user-config-loader")
        );
        unMountPreviousComponent("payments-components");
        ReactDOM.render(
          <FailureForCTA
            header={"Generate Payment Link"}
            msg={error.response.data}
          />,
          document.getElementById("failure-popup")
        );
      })
      .finally(() => {
        // finally setting form submission false.
        action.setSubmitting(false);
      });
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="ui-form-details">
          <div className="ui-form-content">
            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="bank_provider"
                name="bank_provider"
                value={isLoading ? "LOADING" : providerApiData.label}
                placeholder="Enter Bank Provider"
                label="Bank Provider"
                required={true}
                disabled={true}
              />

              {/* **provider (Uncomment below component and comment above one.) */}
              {/* <TextFieldSelect
                id="bank_provider"
                name="bank_provider"
                onChange={(selectedOption) =>
                  formik.setFieldValue("bank_provider", selectedOption)
                }
                onBlur={() =>
                  formik.setFieldTouched("bank_provider", true)
                }
                value={formik.values.bank_provider}
                options={providerApiData}
                noOptionsMessage={() => "No Provider Exists"}
                label="Bank Provider"
                required={true}
                isLoading={isLoading}
                placeholder="Bank Provider"
                isformatOptionLabel={true}
              /> */}
              <TextFieldInput
                id="reference_id"
                name="reference_id"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.reference_id}
                touched={formik.touched.reference_id}
                error={formik.errors.reference_id}
                placeholder="Enter Reference ID"
                label="Reference ID"
                required={true}
                disabled={false}
              />
            </div>

            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="payee_account"
                name="payee_account"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.payee_account}
                touched={formik.touched.payee_account}
                error={formik.errors.payee_account}
                placeholder="Enter Payee Account"
                label="Payee Account"
                required={true}
                disabled={false}
              />
              <TextFieldInput
                id="amount"
                name="amount"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.amount}
                touched={formik.touched.amount}
                error={formik.errors.amount}
                placeholder="Enter Amount"
                label="Amount"
                required={true}
                disabled={false}
                isToolTip={true ? "A minimum of INR 5 is accepted" : null}
              />
            </div>

            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="purpose_message"
                name="purpose_message"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.purpose_message}
                touched={formik.touched.purpose_message}
                error={formik.errors.purpose_message}
                placeholder="Enter Purpose Message"
                label="Message"
                required={true}
                disabled={false}
                isToolTip={
                  true ? "A purpose message for this transaction" : null
                }
              />
              <TextFieldInput
                id="expiry_time"
                name="expiry_time"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.expiry_time}
                touched={formik.touched.expiry_time}
                error={formik.errors.expiry_time}
                placeholder="Enter Expiry Time(in mins)"
                label="Expiry"
                required={false}
                disabled={false}
                isToolTip={true ? "Expiry time in mins" : null}
              />
            </div>

            <div className="ui-form-inputs-section">
              <div className="qr-toggle-container">
                <span className="qr-toggle-container-label">
                  Generate QR
                  <Tooltip requiredField={false} text="Generates QR">
                    <button className="info-icon">
                      <img src="/images/info-icon.svg" alt="information icon" />
                    </button>
                  </Tooltip>
                </span>
                <div className="toggle-section">
                  <span className={!generateQR ? "text-toggle" : undefined}>
                    No
                  </span>
                  <input
                    id="switch-two"
                    className="checkbox"
                    name="qrToggle"
                    value={generateQR}
                    checked={generateQR}
                    onChange={() => setGenerateQR((prev) => !prev)}
                    type="checkbox"
                  />
                  <label htmlFor="switch-two" className="toggle"></label>
                  <span className={generateQR ? "text-toggle" : undefined}>
                    Yes
                  </span>
                </div>
              </div>
            </div>

            <div className="ui-button-container">
              <button
                type="submit"
                className={`submit-btn ${
                  formik.isValid && formik.dirty && !formik.isSubmitting
                    ? "active"
                    : ""
                }`}
                disabled={!formik.isValid || formik.isSubmitting}
              >
                {formik.isSubmitting ? "Loading..." : "Submit"}
                <span
                  id="user-config-loader"
                  style={{ display: "flex" }}
                ></span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default UpiPaymentLinkForm;
