// React and related imports
import React, { useEffect } from "react";
// Custom components
import ReportsForm from "../../components/ReportsFrom/ReportsForm.js";
// Utility functions
import posthog from "posthog-js";
import { capturePosthogEventCustom } from "../../utilities/postHogCustomUtils.js";
// Styles
import "./Reports.scss";

function Reports() {
  // Posthog event capture on page visit
  useEffect(() => {
    if (
      process.env.REACT_APP_ENV_NAME === "staging" ||
      process.env.REACT_APP_ENV_NAME === "production"
    ) {
      if (window?.location?.origin && window?.location?.pathname) {
        try {
          capturePosthogEventCustom(
            window.location.origin,
            window.location.pathname,
            "Generate Reports View",
            {
              additionalInfo: "User viewed Generate Reports page",
            }
          );
        } catch (error) {
          console.error("Error capturing Posthog event:", error);
        }
      } else {
        console.warn(
          "Unable to capture event: window.location properties are not fully defined"
        );
      }
    }
  }, []);
  return (
    <React.Fragment>
      <div className="ReportsMainDialog">
        <ReportsForm />
      </div>
    </React.Fragment>
  );
}

export default Reports;
