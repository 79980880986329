import { useContext, useEffect } from "react";
// Components
import TimeCell from "../../utilities/TimeCell";
import StatusCell from "../../utilities/StatusCell/StatusCell";
import DownloadCell from "../../utilities/DownloadCell/DownloadCell";
import SkeletonEmptyDialogue from "../SkeletonComponents/SkeletonEmptyDialogue";
import ReactDataTable from "../Common/ReactDataTable";
// Contexts
import { DownloadReportsContextProvider } from "./Contexts/DownloadReportsContext";
// Utils
import posthog from "posthog-js";
import { capturePosthogEventCustom } from "../../utilities/postHogCustomUtils";
import moment from "moment";
// Styles
import "./DownloadReport.scss";

const transactionColumnList = [
  {
    accessor: "urn",
    name: "Decentro URN",
    showInitial: true,
  },
  {
    accessor: "report_type",
    name: "Report Type",
    showInitial: true,
    hasPopup: true,
  },
  {
    accessor: "request_time",
    name: "Created On",
    showInitial: true,
    cell: TimeCell,
  },
  {
    accessor: "start_date",
    name: "Start Date",
    showInitial: true,
    cell: (row) => moment(row.getValue(), "YYYY-MM-DD").format("DD/MM/YYYY"),
  },
  {
    accessor: "end_date",
    name: "End Date",
    showInitial: true,
    cell: (row) => moment(row.getValue(), "YYYY-MM-DD").format("DD/MM/YYYY"),
  },
  {
    accessor: "status",
    name: "Status",
    showInitial: true,
    hasPopup: true,
    cell: StatusCell,
  },
  {
    accessor: "",
    name: "Download",
    showInitial: true,
    cell: DownloadCell,
  },
];

const DownloadReports = () => {
  const {
    isError,
    errorResponse,
    tableData,
    totalCount,
    filterList,
    fetchData,
    firstApiCall,
  } = useContext(DownloadReportsContextProvider);

  // Posthog event capture on page visit
  useEffect(() => {
    if (
      process.env.REACT_APP_ENV_NAME === "staging" ||
      process.env.REACT_APP_ENV_NAME === "production"
    ) {
      if (window?.location?.origin && window?.location?.pathname) {
        try {
          capturePosthogEventCustom(
            window.location.origin,
            window.location.pathname,
            "Report Downloads View",
            {
              additionalInfo: "User viewed Report Downloads page",
            }
          );
        } catch (error) {
          console.error("Error capturing Posthog event:", error);
        }
      } else {
        console.warn(
          "Unable to capture event: window.location properties are not fully defined"
        );
      }
    }
  }, []);

  useEffect(() => {
    if (tableData.length >= totalCount && tableData.length !== 0) return;
    const prefetchLimit = 200;
    const endIndex = filterList.currentPage * filterList.resultsPerPage;
    const limitConstant =
      Math.ceil((endIndex + 1) / prefetchLimit) * prefetchLimit +
      2 * filterList?.resultsPerPage;
    if (totalCount > 0) {
      if (tableData.length < limitConstant && firstApiCall === false) {
        fetchData(tableData.length + 1, undefined, undefined, undefined, false);
      }
    } else if (
      totalCount === 0 &&
      tableData.length === 0 &&
      firstApiCall === true
    ) {
      fetchData(tableData.length + 1, undefined, undefined, undefined, false);
    }
  }, [filterList.currentPage]);

  return (
    <>
      {!isError ? (
        <ReactDataTable
          tableTitle={"Downloads"}
          isModalPresent={true}
          columnList={transactionColumnList}
          provider={DownloadReportsContextProvider}
          showDownloadBtn={false}
          showEntries={false}
          showDateFilter={false}
          showSearchParameter={{
            bank_ref_number: false,
            decentro_urn: true,
            customer_ref_number: false,
          }}
        />
      ) : (
        <SkeletonEmptyDialogue type={errorResponse} />
      )}
    </>
  );
};

export default DownloadReports;
