// React Imports
import { useContext, useEffect, useRef, useState } from "react";
// Contexts
import CallbackActionContext from "../../../contexts/CallbackActionContext";
// Components
import HttpTypeDropdown from "./HttpTypeDropDown/HttpTypeDropDown";
import CallbackTypeDropdown from "./CallbackTypeDropdown/CallbackTypeDropdown";
import Modal from "./Modal/Modal";
import Tooltip from "../../Shared/Tooltip/Tooltip";
// Utils
import { randomUUID } from "../../../services/randomUUID";
import { capturePosthogEventCustom } from "../../../utilities/postHogCustomUtils";
// Styles
import "./NewCallbackStyles.scss";

const NewCallback = () => {
  // Context
  const {
    addNewCallback,
    cardType,
    allCallback,
    tableEditData,
    showModal,
    addCallbackApi,
    callbackTypeName,
    setCallbackTypeName,
    httpTypeName,
    setHttpTypeName,
    headerField,
    setHeaderField,
    urlField,
    setUrlField,
  } = useContext(CallbackActionContext);

  // Button Validation
  const [isDisabled, setIsDisabled] = useState(true);
  // Input Validations.
  const headerValidtion = useRef(false);
  const urlValidation = useRef(false);
  // Header input value handler
  const headerInputHandler = (e) => {
    setHeaderField(e.target.value);
    if (e.target.value === "" || e.target.value.length < 1) {
      headerValidtion.current = false;
    } else {
      try {
        JSON.parse(e.target.value);
        headerValidtion.current = false;
      } catch (e) {
        headerValidtion.current = true;
      }
    }
  };
  // url input value handler
  const urlInputHandler = (e) => {
    setUrlField(e.target.value);
    const urlRegExp =
      /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    if (urlRegExp.test(e.target.value)) {
      urlValidation.current = false;
    } else {
      urlValidation.current = true;
    }
  };
  // if tableEdit, then auto filling input fields.
  useEffect(() => {
    if (tableEditData.edit) {
      setHeaderField(JSON.stringify(tableEditData?.item?.header) || "");
      setUrlField(tableEditData?.item?.url || "");
    }
  }, [tableEditData]);

  // Submit callback handler
  const submitHandler = () => {
    // api payload
    let payload = {
      reference_id: randomUUID(),
      subscriber_url: urlField,
      http_method: httpTypeName, // https type name - new upload
      subscription_protocol:
        urlField.indexOf("https://") === 0 ? "https" : "http",
      ...(headerField.length > 0 && {
        subscription_headers: JSON.parse(headerField),
      }),
      callback_type: callbackTypeName, // callback type - updated payload
    };

    addCallbackApi(!!tableEditData.edit, payload);

    // // reset input's on submit
  };

  useEffect(() => {
    if (
      !!callbackTypeName &&
      !!httpTypeName &&
      !!urlField &&
      urlField.length !== 0 &&
      !headerValidtion.current &&
      !urlValidation.current
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [
    headerValidtion,
    urlValidation,
    callbackTypeName,
    httpTypeName,
    headerField,
    urlField,
    tableEditData,
  ]);

  const tooltipText = (
    <div>
      <strong>Example :</strong>
      <br />
      {JSON.stringify({
        ContentType: "application/json",
        abc: "xxxxxxx",
      })}
    </div>
  );

  return (
    <>
      <div className="callback-container">
        <div className="add-callback">
          {/* HEADER */}
          <div onClick={allCallback} className="header-container">
            <img src="/images/back.svg" alt="back-icon" />
            <h1 className="heading">
              {tableEditData.edit ? "Edit" : "Configure New"} Callback
            </h1>
          </div>
        </div>
        <div className="callback-divider"></div>
        <div className="callback-input-container">
          <div className="input-wrapper">
            {/* UPPER INPUTS */}
            <div className="input-group__upper">
              {/* callback Dropdown */}
              <CallbackTypeDropdown setCallbackTypeName={setCallbackTypeName} />
              {/* HTTP Dropdown */}
              <HttpTypeDropdown setHttpTypeName={setHttpTypeName} />
            </div>
            {/* LOWER INPUTS */}
            <div className="input-group__lower">
              {/* header input */}
              <div className="text-input-wrapper">
                <div className="label-wrapper">
                  <div className="text-input-label" id="not-mandatory">
                    Header
                  </div>
                  <Tooltip direction="top" text={tooltipText}>
                    <button className="info-icon">
                      <img src="/images/info-icon.svg" alt="information icon" />
                    </button>
                  </Tooltip>
                </div>
                <div
                  className={`add-callback-input ${
                    headerValidtion.current === true && "error-border"
                  } `}
                >
                  <input
                    type="text"
                    placeholder='{"key1": "value", "key 2": "value2"}'
                    value={headerField}
                    className="callback-field-input"
                    onChange={headerInputHandler}
                  />
                </div>
              </div>
              {/* url input */}
              <div className="text-input-wrapper">
                <div className="text-input-label">URL</div>
                <div
                  className={`add-callback-input ${
                    urlValidation.current === true && "error-border"
                  } `}
                >
                  <input
                    type="text"
                    placeholder="https://www.placeholder.com"
                    value={urlField}
                    className="callback-field-input"
                    onChange={urlInputHandler}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* update and submit callback btn */}
          <div className="button-wrapper">
            <button
              className={`submit-callback-details ${
                !isDisabled && "active-callback-btn"
              }`}
              onClick={() => {
                // Posthog tracking
                if (
                  process.env.REACT_APP_ENV_NAME === "staging" ||
                  process.env.REACT_APP_ENV_NAME === "production"
                ) {
                  if (!tableEditData.edit) {
                    if (
                      window?.location?.origin &&
                      window?.location?.pathname
                    ) {
                      try {
                        capturePosthogEventCustom(
                          window.location.origin,
                          window.location.pathname,
                          "Add New Callback CTA Submit",
                          {
                            additionalInfo:
                              "User submitted Add New Callback form",
                          }
                        );
                      } catch (error) {
                        console.error("Error capturing Posthog event:", error);
                      }
                    } else {
                      console.warn(
                        "Unable to capture event: window.location properties are not fully defined"
                      );
                    }
                  } else {
                    if (
                      window?.location?.origin &&
                      window?.location?.pathname
                    ) {
                      try {
                        capturePosthogEventCustom(
                          window.location.origin,
                          window.location.pathname,
                          "Edit Callback CTA Submit",
                          {
                            additionalInfo: "User submitted Edit Callback form",
                          }
                        );
                      } catch (error) {
                        console.error("Error capturing Posthog event:", error);
                      }
                    } else {
                      console.warn(
                        "Unable to capture event: window.location properties are not fully defined"
                      );
                    }
                  }
                }

                submitHandler();
              }}
              type="button"
              disabled={isDisabled}
            >
              {tableEditData.edit ? "Update" : "Submit"}
            </button>
          </div>
        </div>
        {/* {tableEditData.edit && (
                    <>
                        <CallbackList />
                    </>
                )} */}
        {showModal ? <Modal /> : null}
      </div>
    </>
  );
};
export default NewCallback;
