// React Imports
import { useContext, useEffect, useRef, useState } from "react";
import ReactDom from "react-dom";
// Contexts
import CallbackActionContext from "../../../../contexts/CallbackActionContext";
// Styles
import { CloseOutlined } from "@material-ui/icons";
import "./Modal.scss";
// Components
import EditHttpDropdown from "./EditHttpTypeDropdown/EditHttpTypeDropdown";

const Modal = () => {
  const [callbackField, setCallbackField] = useState("");
  const [headerField, setHeaderField] = useState("");
  const [urlField, setUrlField] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const { setShowModal, editCallbackItem } = useContext(CallbackActionContext);
  // Validations
  const headerValidtion = useRef(false);
  const urlValidation = useRef(false);
  const modalRef = useRef();
  // autoFill fields side Effect
  useEffect(() => {
    setCallbackField(
      editCallbackItem?.callbackType?.replaceAll("_", " ") || ""
    );
    setHeaderField(JSON.stringify(editCallbackItem?.subscriptionHeaders) || "");
    setUrlField(editCallbackItem?.subscriberUrl || "");
  }, [editCallbackItem]);

  const closeModal = (e) => {
    if (e.target === modalRef.current) {
      setShowModal(false);
    }
  };
  // Header input handler
  const headerInputHandler = (e) => {
    setHeaderField(e.target.value);
    try {
      JSON.parse(e.target.value);
      headerValidtion.current = false;
    } catch (e) {
      headerValidtion.current = true;
    }
  };
  // url InputHandler
  const urlInputHandler = (e) => {
    setUrlField(e.target.value);
    let urlRegExp =
      /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    if (urlRegExp.test(e.target.value)) {
      urlValidation.current = false;
    } else {
      urlValidation.current = true;
    }
  };
  return ReactDom.createPortal(
    <div className="container" ref={modalRef} onClick={closeModal}>
      <div className="edit-callback-modal">
        {/* close modal icon */}
        <button className="close-modal-btn" onClick={() => setShowModal(false)}>
          <CloseOutlined />
        </button>
        {/* modal header */}
        <div className="edit-callback-heading">
          <p>Edit Callback</p>
        </div>
        <p className="edit-callback-subtitle">
          Here are some quick details you need to edit
        </p>
        {/* modal fields */}
        <div className="edit-callback-fields">
          {/* callback input */}
          <div className="text-input-wrapper">
            <div className="text-input-label">Callback</div>
            <div className={`add-callback-input callback-read-only`}>
              <input
                type="text"
                value={callbackField}
                placeholder="Enter Callback"
                className="callback-field-input callback-default-filled"
                readOnly
              />
            </div>
          </div>
          {/* http dropdown */}
          <div className="dropdown-input-wrapper">
            <div className="http-input-label">Http</div>
            <EditHttpDropdown editData={editCallbackItem} />
            {/* <EditHttpDropdown
                            setHttpId={setHttpId}
                            editData={editCallbackItem}
                        /> */}
          </div>
          {/* header input */}
          <div className="text-input-wrapper">
            <div className="text-input-label">Header</div>
            <div
              className={`add-callback-input ${
                headerValidtion.current === true && "error-border"
              } `}
            >
              <input
                type="text"
                placeholder="Enter header"
                value={headerField}
                className="callback-field-input"
                onChange={headerInputHandler}
              />
            </div>
          </div>
          {/* url input */}
          <div className="text-input-wrapper">
            <div className="text-input-label">Url</div>
            <div
              className={`add-callback-input ${
                urlValidation.current === true && "error-border"
              } `}
            >
              <input
                type="text"
                placeholder="Enter Url"
                value={urlField}
                className="callback-field-input"
                onChange={urlInputHandler}
              />
            </div>
          </div>
        </div>
        {/* update callback btn */}
        <div className="edit-button-wrapper">
          <button
            className={`update-callback-details ${
              !isDisabled && "active-update-btn"
            }`}
            onClick={() => {
              // submitHandler();
              setShowModal(false);
            }}
            type="button"
            disabled={isDisabled}
          >
            Submit
          </button>
        </div>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
export default Modal;
