import posthog from "posthog-js";

// Helper function to get user info
const getUserInfo = () => {
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  return {
    customer_name: user.client_id,
    user_email: user.email,
  };
};

// Main event capture function
export const capturePosthogEventCustom = (
  origin,
  pathname,
  eventName,
  additionalProperties = {}
) => {
  const target = origin + pathname;
  const isProduction = ["staging", "production"].includes(
    process.env.REACT_APP_ENV_NAME
  );

  if (!isProduction || !origin || !pathname || !eventName) return;

  const properties = {
    path: { target },
    ...getUserInfo(),
    ...additionalProperties,
  };

  posthog.capture(eventName, properties);
};

// Function to capture logout event
export const captureLogoutCustom = (origin, pathname) => {
  capturePosthogEventCustom(origin, pathname, "Logout CTA");
};
