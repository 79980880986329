import { useContext, useEffect } from "react";
// Components
import SkeletonEmptyDialogue from "../SkeletonComponents/SkeletonEmptyDialogue";
import AddIPModal from "./AddIPModal/AddIPModal";
import ReactDataTable from "../Common/ReactDataTable";
import StatusCell from "../../utilities/StatusCell/StatusCell";
import FeatureUnavailable from "../UI/FeatureUnavailable/FeatureUnavailable";
// Contexts
import { ManageIPContextProvider } from "./ManageIPContext";
// Utils
import posthog from "posthog-js";
import { capturePosthogEvent } from "../../utilities/posthogUtils";
import { capturePosthogEventCustom } from "../../utilities/postHogCustomUtils";
// styles import
import "./ManageIPs.scss";

const transactionColumnList = [
  {
    accessor: "serial",
    name: "Serial No.",
    showInitial: true,
  },
  {
    accessor: "ip_address",
    name: "IP Address",
    showInitial: true,
  },
  {
    accessor: "status",
    name: "Status",
    showInitial: true,
    hasPopup: true,
    cell: StatusCell,
  },
];

// CTA for Validating Account
const ValidateCTA = ({ openCTA, CTADisabled }) => {
  const { isLoading } = useContext(ManageIPContextProvider);
  return (
    <div className="manage-ip-container">
      {CTADisabled ? (
        <p className="action-alert-text">
          You can add up to 4 IPs. <br /> For assistance, contact
          <a href="mailto:ops@decentro.tech"> ops@decentro.tech</a>
        </p>
      ) : (
        ""
      )}
      <button
        disabled={CTADisabled}
        className={isLoading || CTADisabled ? "btn-loading" : "manage-ips-btn"}
        style={{ backgroundColor: "#0092ff" }}
        onClick={() => {
          if (!isLoading) {
            if (
              process.env.REACT_APP_ENV_NAME === "staging" ||
              process.env.REACT_APP_ENV_NAME === "production"
            ) {
              capturePosthogEvent(
                window.location.origin,
                window.location.pathname,
                "cta"
              );
            }
            openCTA();
          }
        }}
      >
        Manage IP
      </button>
    </div>
  );
};

const ManageIPs = () => {
  const {
    initialLoading,
    isError,
    errorResponse,
    refreshTable,
    setShowModalDialog,
    showModalDialog,
    tableData,
    totalCount,
    filterList,
    fetchData,
    firstApiCall,
    allowedCount,
  } = useContext(ManageIPContextProvider);

  // Posthog event capture on page visit
  useEffect(() => {
    if (
      process.env.REACT_APP_ENV_NAME === "staging" ||
      process.env.REACT_APP_ENV_NAME === "production"
    ) {
      if (window?.location?.origin && window?.location?.pathname) {
        try {
          capturePosthogEventCustom(
            window.location.origin,
            window.location.pathname,
            "Manage IPs View",
            {
              additionalInfo: "User viewed Manage IPs page",
            }
          );
        } catch (error) {
          console.error("Error capturing Posthog event:", error);
        }
      } else {
        console.warn(
          "Unable to capture event: window.location properties are not fully defined"
        );
      }
    }
  }, []);

  useEffect(() => {
    if (tableData.length >= totalCount && tableData.length !== 0) return;
    const prefetchLimit = 200;
    const endIndex = filterList.currentPage * filterList.resultsPerPage;
    const limitConstant =
      Math.ceil((endIndex + 1) / prefetchLimit) * prefetchLimit +
      2 * filterList?.resultsPerPage;
    if (totalCount > 0) {
      if (tableData.length < limitConstant && firstApiCall === false) {
        fetchData(tableData.length + 1, undefined, undefined, undefined, false);
      }
    } else if (
      totalCount === 0 &&
      tableData.length === 0 &&
      firstApiCall === true
    ) {
      fetchData(tableData.length + 1, undefined, undefined, undefined, false);
    }
  }, [filterList.currentPage]);

  function openCTA() {
    setShowModalDialog(true);
  }
  const isDisabled = allowedCount >= 4 ? true : false;

  if (process.env.REACT_APP_ENV_NAME === "staging") {
    return <FeatureUnavailable />;
  }

  return (
    <>
      {!isError ? (
        <ReactDataTable
          tableTitle={"Manage IPs"}
          isModalPresent={true}
          modalComponent={
            <ValidateCTA openCTA={openCTA} CTADisabled={isDisabled} />
          }
          columnList={transactionColumnList}
          provider={ManageIPContextProvider}
          ModalDialog={<AddIPModal goBackToTable={refreshTable} />}
          showDownloadBtn={false}
          showEntries={false}
          showFilters={{
            search: false,
            date: false,
            entries: false,
            columns: false,
            refresh: true,
          }}
        />
      ) : (
        <SkeletonEmptyDialogue type={errorResponse} />
      )}
    </>
  );
};

export default ManageIPs;
