import { useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom";
// Formik Imports
import { useFormik } from "formik";
import * as Yup from "yup";
// Axios Imports
import Axios from "axios";
// Components
import TextFieldSelect from "../../../UI/TextField/TextFieldSelect";
import TextFieldInput from "../../../UI/TextField/TextFieldInput";
import MoneyTransferSuccessDialog from "../../../MoneyTransferSuccessDialog/MoneyTransferSuccessDialog";
import unMountPreviousComponent from "../../../../utilities/unMountPreviousComponent";
import FailureForCTA from "../../../Shared/FailureForCTA/FailureForCTA";
// Utils
import { REGEXP } from "../../../utilities/validators/inputValidators";
import { APIConfig } from "../../../../services/apiConfiguration";
import apiEndpointList from "../../../../config/core_banking/endpoint";
import { capturePosthogEventCustom } from "../../../../utilities/postHogCustomUtils";
// Style
import "./InitiatePayoutModalForm.scss";

function InitiatePayoutModalFormFormik({ data, tableEditRow }) {
  const CancelToken = Axios.CancelToken;
  const source = CancelToken.source();

  // Bank Providers
  // let transferTypeArray = ["RTGS", "NEFT", "UPI", "IMPS"];
  // const transferTypes = transferTypeArray.map(value => ({ value, label: value }));

  // for holding Provider Bank
  const [providerApiData, setProviderApiData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [provider, setProvider] = useState("");

  // for holding Beneficiaries
  const [beneficiaryOptions, setBeneficiaryOptions] = useState([]);
  const [beneficiaryLoading, setBeneficiaryLoading] = useState(false);

  // for holding from Accounts Options
  const [fromOptions, setFromOptions] = useState([]);
  const [fromAccountLoading, setFromAccountLoading] = useState(false);

  // for holding Provider Bank
  const [transferTypes, setTransferTypes] = useState([]);
  const [transferTypesLoading, setTransferTypesLoading] = useState(false);
  // const [provider, setProvider] = useState("");

  const initialValues = {
    bank_provider: "",
    select_beneficiary: "",
    message: "",
    reference_id: "",
    beneficiary_name: "",
    transfer_type: "",
    to_account: "",
    from_account: "",
    beneficiary_ifsc: "",
    transfer_amt: "",
    beneficiary_mobile: "",
    beneficiary_email: "",
    upi_id: "",
  };

  const {
    messageRegex,
    referenceIdRegex,
    beneficiaryNamePayoutsRegex,
    ifscRegex,
    emailRegex,
    amountRegex,
    phoneNumberRegex,
    accountNumberRegex,
    UPIIDRegex,
  } = REGEXP;

  // Schema for validating form fields corresponding to regex imported.
  const validationSchema = Yup.object({
    bank_provider: Yup.mixed().required("Bank Provider is required"),
    select_beneficiary: Yup.mixed().notRequired(),
    message: Yup.string()
      .matches(messageRegex)
      .required("Purpose Message is required"),
    reference_id: Yup.string()
      .matches(referenceIdRegex)
      .required("Reference ID is required"),
    beneficiary_name: Yup.string().when("select_beneficiary", {
      is: (select_beneficiary) => !select_beneficiary,
      then: () => Yup.string().matches(beneficiaryNamePayoutsRegex).required(),
      otherwise: () => Yup.string().notRequired(),
    }),
    transfer_type: Yup.mixed().required("Transfer Type is required"),
    to_account: Yup.string().when("select_beneficiary", {
      is: (select_beneficiary) => !select_beneficiary,
      // then: () => Yup.string().matches(accountNumberRegex).required(),
      // otherwise: () => Yup.string().notRequired(),
      then: () => Yup.string().matches(accountNumberRegex),
      otherwise: () => Yup.string().notRequired(),
    }),
    from_account: Yup.mixed().required("Account Number is required"),
    beneficiary_ifsc: Yup.string().when("select_beneficiary", {
      is: (select_beneficiary) => !select_beneficiary,
      // then: () => Yup.string().matches(ifscRegex).required(),
      // otherwise: () => Yup.string().notRequired(),
      then: () => Yup.string().matches(ifscRegex),
      otherwise: () => Yup.string().notRequired(),
    }),
    transfer_amt: Yup.string()
      .matches(amountRegex)
      .required("Amount is required"),
    beneficiary_mobile: Yup.string().matches(phoneNumberRegex).notRequired(),
    beneficiary_email: Yup.string().matches(emailRegex).notRequired(),
    upi_id: Yup.string().when("transfer_type", {
      is: (transfer_type) => transfer_type?.value === "UPI",
      then: () =>
        Yup.string().matches(UPIIDRegex).required("UPI ID is required"),
      otherwise: () => Yup.string().notRequired(),
    }),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,

    // This function will run when user will submit the form after it is validated.
    onSubmit: (values, action) => {
      let payloadData;
      if (
        formik.values.select_beneficiary &&
        formik.values.transfer_type?.value != "UPI"
      ) {
        payloadData = {
          beneficiary_code: values.select_beneficiary.value.beneficiary_code,
          reference_id: values.reference_id,
          purpose_message: values.message,
          from_account: values.from_account.value,
          to_account: values.to_account,
          transfer_type: values.transfer_type.value,
          transfer_amount: values.transfer_amt,
        };
      } else if (
        formik.values.select_beneficiary &&
        formik.values.transfer_type?.value === "UPI"
      ) {
        payloadData = {
          beneficiary_code: values.select_beneficiary.value.beneficiary_code,
          reference_id: values.reference_id,
          purpose_message: values.message,
          from_account: values.from_account.value,
          to_upi: values.upi_id,
          transfer_type: values.transfer_type.value,
          transfer_amount: values.transfer_amt,
        };
      } else {
        payloadData =
          formik?.values?.transfer_type?.value === "UPI"
            ? {
                // beneficiary_code: values.beneficiary_name,
                from_account: values.from_account.value,
                purpose_message: values.message,
                reference_id: values.reference_id,
                to_upi: values.upi_id,
                transfer_amount: values.transfer_amt,
                transfer_type: values.transfer_type.value,
                beneficiary_details: {
                  email_address: values.beneficiary_email,
                  mobile_number: values.beneficiary_mobile,
                  payee_name: values.beneficiary_name,
                },
              }
            : {
                // beneficiary_code: values.beneficiary_name,
                from_account: values.from_account.value,
                purpose_message: values.message,
                reference_id: values.reference_id,
                to_account: values.to_account,
                transfer_amount: values.transfer_amt,
                transfer_type: values.transfer_type.value,
                beneficiary_details: {
                  email_address: values.beneficiary_email,
                  mobile_number: values.beneficiary_mobile,
                  payee_name: values.beneficiary_name,
                  ifsc_code: values.beneficiary_ifsc,
                },
              };
      }
      if (
        process.env.REACT_APP_ENV_NAME === "staging" ||
        process.env.REACT_APP_ENV_NAME === "production"
      ) {
        if (window?.location?.origin && window?.location?.pathname) {
          try {
            capturePosthogEventCustom(
              window.location.origin,
              window.location.pathname,
              "Initiate Payout CTA Submit",
              {
                additionalInfo: "User submitted Initiate Payout details",
              }
            );
          } catch (error) {
            console.error("Error capturing Posthog event:", error);
          }
        } else {
          console.warn(
            "Unable to capture event: window.location properties are not fully defined"
          );
        }
      }
      initiateMoneyTransferApi(payloadData, action);
    },
  });

  const initiateMoneyTransferApi = (payload, action) => {
    APIConfig.API_Client.defaults.headers.post["provider_code"] = provider;
    action.setSubmitting(true);

    try {
      APIConfig.API_Client.post(
        apiEndpointList.MONEY_TRANSFER.baseUrl +
          apiEndpointList.MONEY_TRANSFER.endpoint,
        payload,
        { cancelToken: source.token }
      )
        .then((response) => {
          unMountPreviousComponent("virtual-accounts-components");

          ReactDOM.render(
            <MoneyTransferSuccessDialog data={response.data} />,
            document.getElementById("virtual-accounts-components")
          );
          action.resetForm();
        })
        .catch((error) => {
          unMountPreviousComponent("virtual-accounts-components");

          try {
            ReactDOM.render(
              <FailureForCTA
                header={"Payout"}
                msg={error.response.data.error.message}
              />,
              document.getElementById("failure-popup")
            );
          } catch (error) {
            ReactDOM.render(
              <FailureForCTA
                header={"Payout"}
                msg={"Something went wrong please try again later"}
              />,
              document.getElementById("failure-popup")
            );
          }
        })
        .finally(() => {
          // finally setting form submission false.
          action.setSubmitting(false);
        });
    } catch (e) {
      console.error(e);
    }
  };

  // Fetching the Providers to show in dropdown.
  const cvaProvider = () => {
    setIsLoading(true);
    APIConfig.API_Client.post(
      apiEndpointList.PROVIDER_ACCOUNTS.baseUrl +
        apiEndpointList.PROVIDER_ACCOUNTS.endpoint,
      {},
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        const options = response.data.map((item) => ({
          value: item.bank_code,
          label: item.bank_name,
          bank_id: item.bank_id,
        }));
        setIsLoading(false);
        setProviderApiData(options);
      })
      .catch((error) => {
        console.error("error from Payout Form", error);
      });
  };

  // Formatting for the react-select
  const beneficiaryOptionsDropdownFormatted = useMemo(() => {
    return beneficiaryOptions.map((item) => ({
      label: item.beneficiary_name,
      value: item,
    }));
  }, [beneficiaryOptions]);

  // Fetching the Beneficiaries to show in dropdown
  const fetchSelectOptions = () => {
    setBeneficiaryLoading(true);
    if (formik?.values?.transfer_type?.value === "UPI") {
      APIConfig.API_Client.post(
        apiEndpointList.UPI_BENEFICIARY_LIST.baseUrl +
          apiEndpointList.UPI_BENEFICIARY_LIST.endpointUpi
      ).then((response) => {
        setBeneficiaryLoading(false);
        setBeneficiaryOptions(response.data);
      });
    } else {
      APIConfig.API_Client.post(
        apiEndpointList.UPI_BENEFICIARY_LIST.baseUrl +
          apiEndpointList.UPI_BENEFICIARY_LIST.endpointNonUpi
      ).then((response) => {
        setBeneficiaryLoading(false);
        setBeneficiaryOptions(response.data);
      });
    }
  };

  // Fetching the From Account Options based on selected bank provider
  const fetchFromAccountOptions = (bankProviderValue) => {
    setFromAccountLoading(true);
    APIConfig.API_Client.post(
      apiEndpointList.TRANSFER_ACCOUNT_LIST.baseUrl +
        apiEndpointList.TRANSFER_ACCOUNT_LIST.endpoint,
      { provider_code: bankProviderValue }
    )
      .then((response) => {
        const options = response.data.map((item) => ({
          label: item.va_numbers,
          value: item.va_numbers,
        }));
        setFromOptions(options);
        setFromAccountLoading(false);
      })
      .catch((error) => {
        console.error("error from Payout Form", error);
      });
  };

  // Fetching 'transfer_types' to show in dropdown
  const fetchTransferTypes = () => {
    setTransferTypesLoading(true);
    APIConfig.API_Client.post(
      apiEndpointList.TRANSFER_TYPES_PAYOUTS.baseUrl +
        apiEndpointList.TRANSFER_TYPES_PAYOUTS.endpoint,
      { bank_code: formik?.values?.bank_provider?.value }
    ).then((res) => {
      setTransferTypesLoading(false);
      setTransferTypes(
        ...res.data.map(({ supported_payment_methods }) =>
          supported_payment_methods?.map((val) => ({ label: val, value: val }))
        )
      );
    });
  };

  // Select beneficiaries options should change on change of transfer_type
  useEffect(() => {
    fetchSelectOptions();
  }, [formik.values.transfer_type]);

  useEffect(() => {
    cvaProvider();
    // fetchFromAccountOptions();
  }, []);

  useEffect(
    () => formik?.values?.bank_provider?.value && fetchTransferTypes(),
    [formik.values.bank_provider]
  );

  useEffect(() => {
    if (provider) {
      fetchFromAccountOptions(provider);
    }
  }, [provider]);

  // Provider change handler to fetch the From Account Options and Transfer Types
  const handleProviderChange = (selectedOption) => {
    formik.setFieldValue("bank_provider", selectedOption);
    if (selectedOption) {
      setProvider(selectedOption.value);
      // fetchTransferTypes(selectedOption.value);
      formik.setFieldValue("from_account", "");
    } else {
      setFromOptions([]);
      setTransferTypes([]);
      formik.setFieldValue("from_account", "");
    }
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="ui-form-details">
          <div className="ui-form-content">
            <div className="ui-form-inputs-section">
              <TextFieldSelect
                id="bank_provider"
                name="bank_provider"
                // onChange={(selectedOption) => {
                //   console.log(selectedOption);
                //   formik.setFieldValue("bank_provider", selectedOption);
                //   formik.setFieldValue("transfer_type", null);

                //   if (selectedOption) {
                //     setProvider(selectedOption?.value);
                //   }
                // }}
                onChange={handleProviderChange}
                onBlur={() => formik.setFieldTouched("bank_provider", true)}
                value={formik.values.bank_provider}
                options={providerApiData}
                noOptionsMessage={() => "No Provider Exists"}
                label="Select Provider"
                required={true}
                isLoading={isLoading}
                placeholder="Bank Provider"
                isformatOptionLabel={true}
              />
              <TextFieldSelect
                id="select_beneficiary"
                name="select_beneficiary"
                onChange={(selectedOption) => {
                  formik.setFieldValue("select_beneficiary", selectedOption);
                  // If user removes the selected option, the pre filled fields also gets erased.
                  if (selectedOption) {
                    formik.setFieldValue(
                      "beneficiary_name",
                      selectedOption.value.beneficiary_name
                    );
                    formik.setFieldValue(
                      "beneficiary_mobile",
                      selectedOption.value.payee_mobile_number
                    );
                    formik.setFieldValue(
                      "beneficiary_email",
                      selectedOption.value.payee_email
                    );
                    if (formik?.values?.transfer_type?.value !== "UPI") {
                      formik.setFieldValue(
                        "beneficiary_ifsc",
                        selectedOption?.value?.ifsc
                      );
                      formik.setFieldValue(
                        "to_account",
                        selectedOption?.value?.beneficiary_account_number
                      );
                    } else {
                      formik.setFieldValue(
                        "upi_id",
                        selectedOption?.value?.beneficiary_upi_id
                      );
                    }
                    setTimeout(() => {
                      formik.validateForm();
                    }, 50);
                  } else {
                    formik.setFieldValue("beneficiary_name", "");
                    formik.setFieldValue("beneficiary_mobile", "");
                    formik.setFieldValue("beneficiary_email", "");
                    formik.setFieldValue("to_account", "");
                    formik.setFieldValue("beneficiary_ifsc", "");
                    formik.setFieldValue("upi_id", "");
                  }
                }}
                onBlur={() =>
                  formik.setFieldTouched("select_beneficiary", true)
                }
                value={formik.values.select_beneficiary}
                options={beneficiaryOptionsDropdownFormatted}
                noOptionsMessage={() => "No Beneficiary Exists"}
                label="Select Beneficiary"
                required={false}
                isLoading={beneficiaryLoading}
                placeholder="Bank Beneficiary"
                isformatOptionLabel={true}
                isToolTip={"Select the already added beneficiary from the list"}
              />
            </div>

            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="message"
                name="message"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.message}
                touched={formik.touched.message}
                error={formik.errors.message}
                placeholder="Enter Message"
                label="Message"
                required={true}
                disabled={false}
                isToolTip={"The purpose message for this payout"}
              />
              <div className="bordered-or-payouts"></div>
            </div>

            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="reference_id"
                name="reference_id"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.reference_id}
                touched={formik.touched.reference_id}
                error={formik.errors.reference_id}
                placeholder="Enter Reference ID"
                label="Reference ID"
                required={true}
                disabled={false}
              />
              <TextFieldInput
                id="beneficiary_name"
                name="beneficiary_name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.beneficiary_name}
                touched={formik.touched.beneficiary_name}
                error={formik.errors.beneficiary_name}
                placeholder="Enter Payee Name"
                label="Beneficiary Name"
                required={true}
                disabled={
                  !formik.values.transfer_type ||
                  !!formik.values.select_beneficiary
                }
              />
            </div>

            <div className="ui-form-inputs-section">
              <TextFieldSelect
                id="transfer_type"
                name="transfer_type"
                onChange={(selectedOption) => {
                  formik.setFieldValue("transfer_type", selectedOption);
                }}
                onBlur={() => formik.setFieldTouched("transfer_type", true)}
                value={formik.values.transfer_type}
                options={transferTypes}
                noOptionsMessage={() => "No Transfer type Exists"}
                label="Transfer type"
                required={true}
                placeholder="Select Option"
                isformatOptionLabel={true}
                isLoading={transferTypesLoading}
                isDisabled={
                  !transferTypes ||
                  transferTypes?.length === 0 ||
                  !formik?.values?.bank_provider
                }
              />
              <TextFieldInput
                id="beneficiary_mobile"
                name="beneficiary_mobile"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.beneficiary_mobile}
                touched={formik.touched.beneficiary_mobile}
                error={formik.errors.beneficiary_mobile}
                placeholder="Enter Customer's mobile number"
                label="Beneficiary Mobile"
                required={false}
                disabled={
                  !formik.values.transfer_type ||
                  !!formik.values.select_beneficiary
                }
              />
            </div>

            <div className="ui-form-inputs-section">
              <TextFieldSelect
                id="from_account"
                name="from_account"
                onChange={(selectedOption) => {
                  formik.setFieldValue("from_account", selectedOption);
                }}
                onBlur={() => formik.setFieldTouched("from_account", true)}
                value={formik.values.from_account}
                options={fromOptions}
                noOptionsMessage={() => "No Account Exists"}
                label="From Account"
                required={true}
                isLoading={fromAccountLoading}
                placeholder="Enter Account Number"
                isformatOptionLabel={true}
                isToolTip={"VA number from where the payout will be done"}
              />
              <TextFieldInput
                id="beneficiary_email"
                name="beneficiary_email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.beneficiary_email}
                touched={formik.touched.beneficiary_email}
                error={formik.errors.beneficiary_email}
                placeholder="Enter Customer's Email"
                label="Beneficiary Email"
                required={false}
                disabled={
                  !formik.values.transfer_type ||
                  !!formik.values.select_beneficiary
                }
              />
            </div>

            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="transfer_amt"
                name="transfer_amt"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.transfer_amt}
                touched={formik.touched.transfer_amt}
                error={formik.errors.transfer_amt}
                placeholder="Enter Transfer Amount"
                label="Transfer Amount"
                required={true}
                disabled={false}
                isToolTip={"Minimum amount of 1.00 Rs"}
              />
              {formik?.values?.transfer_type &&
              formik?.values?.transfer_type?.value === "UPI" ? (
                <TextFieldInput
                  id="upi_id"
                  name="upi_id"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.upi_id}
                  touched={formik.touched.upi_id}
                  error={formik.errors.upi_id}
                  placeholder="Enter Recepient"
                  label="To UPI"
                  required={true}
                  disabled={false}
                />
              ) : (
                <TextFieldInput
                  id="to_account"
                  name="to_account"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.to_account}
                  touched={formik.touched.to_account}
                  error={formik.errors.to_account}
                  placeholder="Enter Recepient"
                  label="To Account"
                  required={true}
                  disabled={
                    !formik.values.transfer_type ||
                    !!formik.values.select_beneficiary
                  }
                  isToolTip={"Beneficiary account number"}
                />
              )}
            </div>

            <div
              className="ui-form-inputs-section"
              style={{
                justifyContent: "flex-end",
              }}
            >
              {formik?.values?.transfer_type &&
                formik?.values?.transfer_type?.value !== "UPI" && (
                  <TextFieldInput
                    className="aman"
                    id="beneficiary_ifsc"
                    name="beneficiary_ifsc"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.beneficiary_ifsc}
                    touched={formik.touched.beneficiary_ifsc}
                    error={formik.errors.beneficiary_ifsc}
                    placeholder="Enter IFSC"
                    label="Beneficiary IFSC"
                    required={true}
                    disabled={
                      !formik.values.transfer_type ||
                      !!formik.values.select_beneficiary
                    }
                    isToolTip={"Bank IFSC code of beneficiary"}
                  />
                )}
            </div>

            <div className="ui-button-container">
              <button
                type="submit"
                // className={`submit-btn ${
                //   formik.isValid && formik.dirty && !formik.isSubmitting
                //     ? "active"
                //     : ""
                // }`}
                // disabled={!formik.isValid || formik.isSubmitting}
                className="submit-btn active"
              >
                {formik.isSubmitting ? "Loading..." : "Submit"}
                <span
                  id="user-config-loader"
                  style={{ display: "flex" }}
                ></span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default InitiatePayoutModalFormFormik;
