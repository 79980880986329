// React and related imports
import React, { useEffect } from "react";
import { useContext } from "react";
// Context provider
import { EmandateContextProvider } from "./EmandateContext";
// Custom components
import ReactDataTable from "../../Common/ReactDataTable";
import Emandate from "./EmandateModal/Emandate/Emandate";
import EmandateRegistrationModal from "./EmandateDataTable/components/EmandateRegistrationModal";
import SkeletonEmptyDialogue from "../../SkeletonComponents/SkeletonEmptyDialogue";
// Utility functions
import StatusCell from "../../../utilities/StatusCell/StatusCell";
import TimeCell from "../../../utilities/TimeCell";
import { capturePosthogEvent } from "../../../utilities/posthogUtils";
import posthog from "posthog-js";
import { capturePosthogEventCustom } from "../../../utilities/postHogCustomUtils";

const paymentColumnList = [
  {
    accessor: "name",
    showInitial: true,
    name: "Customer Name",
  },
  {
    accessor: "mobile_number",
    showInitial: true,
    name: "Mobile Number",
  },
  {
    accessor: "start_date",
    showInitial: true,
    name: "Start Date",
    cell: TimeCell,
  },
  {
    accessor: "end_date",
    showInitial: true,
    name: "End Date",
    cell: TimeCell,
  },
  {
    accessor: "frequency",
    showInitial: true,
    name: " Frequency",
  },
  {
    accessor: "amount",
    showInitial: true,
    name: "Amount",
  },
  {
    accessor: "status",
    name: "Status",
    showInitial: true,
    hasPopup: true,
    cell: StatusCell,
  },
  { accessor: "umrn_number", name: "UMRN", showInitial: true },
];
const MandateCTA = ({ openUpiLink }) => {
  return (
    <>
      <button
        className="virtual-accounts-btn"
        style={{ backgroundColor: "#0092ff" }}
        onClick={() => {
          if (
            process.env.REACT_APP_ENV_NAME === "staging" ||
            process.env.REACT_APP_ENV_NAME === "production"
          ) {
            capturePosthogEvent(
              window.location.origin,
              window.location.pathname,
              "cta"
            );
          }
          openUpiLink();
        }}
      >
        Create eMandate
      </button>
    </>
  );
};
const EmandateTable = () => {
  const {
    initialLoading,
    isError,
    errorResponse,
    refreshTable,
    setShowModalDialog,
    firstApiCall,
    filterList,
    tableData,
    totalCount,
    fetchData,
  } = useContext(EmandateContextProvider);

  // Posthog event capture on page visit
  useEffect(() => {
    if (
      process.env.REACT_APP_ENV_NAME === "staging" ||
      process.env.REACT_APP_ENV_NAME === "production"
    ) {
      if (window?.location?.origin && window?.location?.pathname) {
        try {
          capturePosthogEventCustom(
            window.location.origin,
            window.location.pathname,
            "eMandate View",
            {
              additionalInfo: "User viewed eMandate page",
            }
          );
        } catch (error) {
          console.error("Error capturing Posthog event:", error);
        }
      } else {
        console.warn(
          "Unable to capture event: window.location properties are not fully defined"
        );
      }
    }
  }, []);

  useEffect(() => {
    if (tableData.length >= totalCount && tableData.length !== 0) return;
    const prefetchLimit = 200;
    const endIndex = filterList.currentPage * filterList.resultsPerPage;
    const limitConstant =
      Math.ceil((endIndex + 1) / prefetchLimit) * prefetchLimit +
      2 * filterList?.resultsPerPage;
    if (totalCount > 0) {
      if (tableData.length < limitConstant && firstApiCall === false) {
        fetchData(tableData.length + 1, undefined, undefined, undefined, false);
      }
    } else if (
      totalCount === 0 &&
      tableData.length === 0 &&
      firstApiCall === true
    ) {
      fetchData(tableData.length + 1, undefined, undefined, undefined, false);
    }
  }, [filterList.currentPage]);

  function openUpiLink() {
    setShowModalDialog(true);
  }
  return (
    <>
      {!isError ? (
        <ReactDataTable
          columnList={paymentColumnList}
          isModalPresent={true}
          // * Hiding CTA temporary for Emandate.
          // modalComponent={<MandateCTA openUpiLink={openUpiLink} />}
          tableTitle={"eMandate"}
          provider={EmandateContextProvider}
          // ModalDialog={<Emandate goBackToTable={refreshTable} />}
          showDownloadBtn={false}
        />
      ) : (
        <SkeletonEmptyDialogue type={errorResponse} />
      )}
    </>
  );
};

export default EmandateTable;
