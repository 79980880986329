import React, { useEffect } from "react";
// Utils import
import posthog from "posthog-js";
import { capturePosthogEventCustom } from "../../../utilities/postHogCustomUtils";
import Enable2FA from "./Enable2FA/Enable2FA";
// styles import
import "./ManageAccount.scss";

const ManageAccount = () => {
  // Posthog event capture on page visit
  useEffect(() => {
    if (
      process.env.REACT_APP_ENV_NAME === "staging" ||
      process.env.REACT_APP_ENV_NAME === "production"
    ) {
      if (window?.location?.origin && window?.location?.pathname) {
        try {
          capturePosthogEventCustom(
            window.location.origin,
            window.location.pathname,
            "Manage 2FA View",
            {
              additionalInfo: "User viewed Manage 2FA page",
            }
          );
        } catch (error) {
          console.error("Error capturing Posthog event:", error);
        }
      } else {
        console.warn(
          "Unable to capture event: window.location properties are not fully defined"
        );
      }
    }
  }, []);
  return (
    <div className="manage-2fa-card">
      {/* Toggle Card */}
      <Enable2FA />
    </div>
  );
};

export default ManageAccount;
