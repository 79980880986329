import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
// Axios Imports
import Axios from "axios";
// Components
import TextFieldInput from "../../UI/TextField/TextFieldInput";
import TextFieldSelect from "../../UI/TextField/TextFieldSelect";
import FailureForCTA from "../../Shared/FailureForCTA/FailureForCTA";
import { Loader } from "rsuite";
import unMountPreviousComponent from "../../../utilities/unMountPreviousComponent";
import ValidateAccountSuccessDialog from "../Success/ValidateAccountSuccessDialog";
// Formik and Yup Imports
import { useFormik } from "formik";
import * as Yup from "yup";
// Utils
import apiEndpointList from "../../../config/core_banking/endpoint";
import { APIConfig } from "../../../services/apiConfiguration";
import { REGEXP } from "../../utilities/validators/inputValidators";
import { capturePosthogEventCustom } from "../../../utilities/postHogCustomUtils";

const ValidateAccountForm = () => {
  const CancelToken = Axios.CancelToken;
  const source = CancelToken.source();

  const validations = ["Penniless", "Pennydrop", "Hybrid"];
  const validationTypes = validations.map((value) => ({
    value: value.toLowerCase(),
    label: value,
  }));

  const Providers = {
    label: "IDFC",
    value: "idfb",
  };

  // for holding Provider Bank
  const [providerApiData, setProviderApiData] = useState(Providers);

  // TODO: KEEPING THIS FOR FUTURE REFERENCE, IN CASE OF MORE THAN ONE PROVIDER AND API AVAILABILITY FROM BACKEND: UNCOMMENT BELOW CODE AND COMMENT ABOVE.
  const [isLoading, setIsLoading] = useState(false);

  //   Calling provider options API
  const cvaProvider = () => {
    setIsLoading(true);
    APIConfig.API_Client.post(
      apiEndpointList.VALIDATE_PROVIDER_ACCOUNTS.baseUrl +
      apiEndpointList.VALIDATE_PROVIDER_ACCOUNTS.endpoint,
      {
        "api_common_name": "CORE_BANKING_MONEY_TRANSFER_VALIDATE_BANK_ACCOUNT"
      },
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        setIsLoading(false);
        const options = response.data.map((value) => ({
          //   value: value.bank_name,
          //   label: value.bank_code,
          label: value.bank_name,
          value: value.bank_code,
        }));
        setProviderApiData(options);
      })
      .catch((error) => { });
  };

  //   Fetching CVA Providers
  useEffect(() => {
    cvaProvider();
  }, []);

  const initialValues = {
    purpose_message: "",
    reference_id: "",
    bank_provider: "",
    validation_type: "",
    beneficiary_account_number: "",
    beneficiary_ifsc: "",
  };

  const {
    messageRegex,
    referenceIdRegex,
    accountNumberRegex,
    ifscRegex,
    nameRegex,
  } = REGEXP;

  // Schema for validating form fields corresponding to regex imported.
  const validationSchema = Yup.object({
    // * Uncomment it if fetching from API(for future reference)
    bank_provider: Yup.mixed().required("Bank Provider is required"),
    validation_type: Yup.mixed().when("bank_provider", {
      is: (bank_provider) => bank_provider?.value === "idfb",
      then: () => Yup.mixed().required("Validation Type is required"),
      otherwise: () => Yup.mixed().notRequired(),
    }),
    purpose_message: Yup.string()
      .matches(messageRegex)
      .required("It's a required field"),
    reference_id: Yup.string().required().matches(referenceIdRegex),
    beneficiary_account_number: Yup.string()
      .matches(accountNumberRegex)
      .required("Account Number is required"),
    beneficiary_ifsc: Yup.string()
      .matches(ifscRegex)
      .required("IFSC is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,

    // This function will run when user will submit the form after it is validated.
    onSubmit: (values, action) => {
      const payloadData = {
        reference_id: values.reference_id,
        purpose_message: values.purpose_message,
        validation_type: values.validation_type.value,
        beneficiary_details: {
          account_number: values.beneficiary_account_number,
          ifsc: values.beneficiary_ifsc,
        },
      };
      //   const providerCode = providerApiData.label;
      const providerCode = provider;
      if (
        process.env.REACT_APP_ENV_NAME === "staging" ||
        process.env.REACT_APP_ENV_NAME === "production"
      ) {
        if (window?.location?.origin && window?.location?.pathname) {
          try {
            capturePosthogEventCustom(
              window.location.origin,
              window.location.pathname,
              "Validate Bank Account CTA Submit",
              {
                additionalInfo: "User submitted Validate Bank Account details",
              }
            );
          } catch (error) {
            console.error("Error capturing Posthog event:", error);
          }
        } else {
          console.warn(
            "Unable to capture event: window.location properties are not fully defined"
          );
        }
      }
      validateAccountAPI(payloadData, providerCode, action);
    },
  });

  // Validate Account API call
  const validateAccountAPI = (payload, provider_code, action) => {
    action.setSubmitting(true);
    ReactDOM.render(<Loader />, document.getElementById("user-config-loader"));

    APIConfig.API_Client.post(
      apiEndpointList.VALIDATE_BANK_ACCOUNT.baseUrl +
      apiEndpointList.VALIDATE_BANK_ACCOUNT.endpoint,
      payload,
      { headers: { provider_code } },
      { cancelToken: source.token }
    )
      .then((response) => {
        action.resetForm();
        ReactDOM.unmountComponentAtNode(
          document.getElementById("user-config-loader")
        );

        ReactDOM.render(
          <ValidateAccountSuccessDialog successResponse={response?.data} />,
          document.getElementById("payments-components")
        );
      })
      .catch((error) => {
        ReactDOM.unmountComponentAtNode(
          document.getElementById("user-config-loader")
        );
        unMountPreviousComponent("payments-components");
        ReactDOM.render(
          <FailureForCTA
            header={"Validate Bank Account"}
            msg={error?.response?.data}
          />,
          document.getElementById("failure-popup")
        );
      })
      .finally(() => {
        // finally setting form submission false.
        action.setSubmitting(false);
      });
  };

  const [provider, setProvider] = useState("");
  // Provider change handler to fetch the From Account Options and Transfer Types
  const handleProviderChange = (selectedOption) => {
    formik.setFieldValue("bank_provider", selectedOption);
    if (selectedOption) {
      setProvider(selectedOption.value);
    }
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="ui-form-details">
          <div className="ui-form-content">
            <div className="ui-form-inputs-section">
              {/* <TextFieldInput
                id="bank_provider"
                name="bank_provider"
                value={providerApiData.label}
                placeholder="Enter Bank Provider"
                label="Bank Provider"
                required={true}
                disabled={true}
              /> */}

              {/* KEEPING THIS FOR FUTURE IN CASE OF MORE THAN ONE PROVIDER */}
              <TextFieldSelect
                id="bank_provider"
                name="bank_provider"
                onChange={handleProviderChange}
                onBlur={() => formik.setFieldTouched("bank_provider", true)}
                value={formik.values.bank_provider}
                options={providerApiData}
                noOptionsMessage={() => "No Provider Exists"}
                label="Select Provider"
                required={true}
                placeholder="Bank Provider"
                isformatOptionLabel={true}
                isLoading={isLoading}
              />
              <TextFieldInput
                id="reference_id"
                name="reference_id"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.reference_id}
                touched={formik.touched.reference_id}
                error={formik.errors.reference_id}
                placeholder="Enter Reference ID"
                label="Reference ID"
                required={true}
                disabled={false}
              />
            </div>

            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="purpose_message"
                name="purpose_message"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.purpose_message}
                touched={formik.touched.purpose_message}
                error={formik.errors.purpose_message}
                placeholder="Enter Message"
                label="Message"
                required={true}
                disabled={false}
                isToolTip={"The purpose of this request"}
              />
              <TextFieldInput
                id="beneficiary_account_number"
                name="beneficiary_account_number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.beneficiary_account_number}
                touched={formik.touched.beneficiary_account_number}
                error={formik.errors.beneficiary_account_number}
                placeholder="Beneficiary Account Number"
                label="Beneficiary Account Number"
                required={true}
              />
            </div>

            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="beneficiary_ifsc"
                name="beneficiary_ifsc"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.beneficiary_ifsc}
                touched={formik.touched.beneficiary_ifsc}
                error={formik.errors.beneficiary_ifsc}
                placeholder="Enter IFSC"
                label="Beneficiary IFSC"
                required={true}
              />
              {formik?.values?.bank_provider?.value === "idfb" && (
                <TextFieldSelect
                  id="validation_type"
                  name="validation_type"
                  onChange={(selectedOption) => {
                    formik.setFieldValue("validation_type", selectedOption);
                  }}
                  onBlur={() => formik.setFieldTouched("validation_type", true)}
                  value={formik.values.validation_type}
                  options={validationTypes}
                  noOptionsMessage={() => "No Validation Type Exists"}
                  label="Validation Type"
                  required={true}
                  placeholder="Select Validation Type"
                  isformatOptionLabel={true}
                  isToolTip={"Either Penniless, Pennydrop, or Hybrid"}
                />
              )}
            </div>

            <div className="ui-button-container">
              <button
                type="submit"
                className="submit-btn active"
              // className={`submit-btn ${
              //   formik.isValid && formik.dirty && !formik.isSubmitting
              //     ? "active"
              //     : ""
              // }`}
              // disabled={!formik.isValid || formik.isSubmitting}
              >
                {formik.isSubmitting ? "Loading..." : "Submit"}
                <span
                  id="user-config-loader"
                  style={{ display: "flex" }}
                ></span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default ValidateAccountForm;
